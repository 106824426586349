















































































































































































































































































































.reset-password-form {
  .el-form-item__error {
    position: relative !important;
  }
}
